import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { NotFoundComponent } from './common/pages/not-found/not-found.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationModule } from '@upkeeplabs/authentication';
import { environment } from '../environments/environment';
import {  MatDialogModule } from '@angular/material/dialog';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ErrorHandlerService } from '@cogent/client/shared/services/error-handler.service';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { MissionService } from '@cogent/client/shared/services/mission-service';

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,
    ],
    // schemas: [NO_ERRORS_SCHEMA],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        AuthenticationModule,
        MatDialogModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: false /* environment.production*/ }),
        MaterialSharedModule,
    ],
    providers: [
        MissionService,
        { provide: 'authConfig', useValue: environment.authConfig },
        { provide: ErrorHandler, useClass: ErrorHandlerService }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(overlayContainer: OverlayContainer) {
        overlayContainer.getContainerElement().classList.add('candy-app-theme');
    }
}
