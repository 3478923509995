import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './common/pages/not-found/not-found.component';
import { AuthGuardService } from '@upkeeplabs/authentication';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@upkeeplabs/authentication').then(mod => mod.AuthenticationModule)
  },
  {
    path: '',
    loadChildren: () => import('./cogent.module').then(mod => mod.CogentModule),
    canActivate: [AuthGuardService]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }