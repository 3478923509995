import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit, AfterViewInit {
    showEasterEggInstructions = false;

    constructor() { }
    ngAfterViewInit(): void {
        const splashScreen = document.getElementById('splashScreen');
        if (splashScreen) {
            splashScreen.style.display = 'none';
        }
    }

    ngOnInit() {
    }

    goBack() {
        window.history.back();
    }

    // lanunchEasterEgg() {
        
    //     var KICKASSVERSION = '2.0'; var s = document.createElement('script'); s.type = 'text/javascript'; document.body.appendChild(s); s.src = '//hi.kickassapp.com/kickass.js';

    //     this.clearMenu();
    // }

    private clearMenu() {
        if (document.getElementById('kickass-menu')) {
            document.getElementById('kickass-menu').remove();
        } else {
            setTimeout(() => this.clearMenu(), 50);
        }
    }

}
