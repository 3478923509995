import { ErrorHandler, Injectable } from "@angular/core";
import { ApplicationInsightsMonitoringService } from "@cogent/client/shared/services/application-insights-monitoring.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private myMonitoringService: ApplicationInsightsMonitoringService) {
        super();
    }

    handleError(error: Error) {
        this.myMonitoringService.logException(error); // Manually log exception
    }
}